.Btn {
    border: none;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.4s;
    cursor: pointer;
    position: relative;
    background: #0274b3;
}

.svgIcon {
    transition-duration: 0.3s;
}

.svgIcon path {
    fill: white;
}

.text {
    position: absolute;
    color: rgb(255, 255, 255);
    width: 120px;
    font-weight: 600;
    opacity: 0;
    transition-duration: 0.4s;
}

.Btn:hover {
    width: 110px;
    transition-duration: 0.4s;
    border-radius: 30px;
}

.Btn:hover .text {
    opacity: 1;
    transition-duration: 0.4s;
}

.Btn:hover .svgIcon {
    opacity: 0;
    transition-duration: 0.3s;
}


@keyframes fly {
    0% {
      transform: translateY(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateY(-200px) scale(0.5);
      opacity: 0;
    }
  }
  
  .emoji {
    position: absolute;
    font-size: 2rem;
    opacity: 0;
    animation: fly 1.5s ease-in-out forwards;
  }
  