.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.sticky-sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
  transition: bottom 0.3s ease-in-out;
}



/* ------------ Mobile Hamburger ---------- */

#hamburger {
  cursor: pointer;
}

#hamburger input {
  display: none;
}

#hamburger svg {
  height: 3em;
  transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line {
  fill: none;
  stroke: #3b3d41;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line-top-bottom {
  stroke-dasharray: 12 63;
}

#hamburger input:checked+svg {
  transform: rotate(-45deg);
}

#hamburger input:checked+svg .line-top-bottom {
  stroke-dasharray: 20 300;
  stroke-dashoffset: -32.42;
}


/* ------------ Fonts ---------- */

@font-face {
  font-family: 'SFREGULAR';
  src: url('./fonts/SFPRODISPLAYREGULAR.OTF') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFMEDIUM';
  src: url('./fonts/SFPRODISPLAYMEDIUM.OTF') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFBOLD';
  src: url('./fonts/SFPRODISPLAYBOLD.OTF') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFULTRALIGHT';
  src: url('./fonts/SFPRODISPLAYULTRALIGHT.OTF') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'UTHMANIC';
  src: url('./fonts/Uthmanic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}